import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import '../styles/main-table.css'
import { fetchAuthSession } from 'aws-amplify/auth'
import { jwtDecode } from 'jwt-decode';


// // Imports the Amplify library from 'aws-amplify' package. This is used to configure your app to interact with AWS services.
// import { Amplify } from 'aws-amplify';

// // Imports the Authenticator and withAuthenticator components from '@aws-amplify/ui-react'.
// // Authenticator is a React component that provides a ready-to-use sign-in and sign-up UI.
// // withAuthenticator is a higher-order component that wraps your app component to enforce authentication.
// import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';

// // Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
// import '@aws-amplify/ui-react/styles.css';

// // Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
// import awsExports from '../aws-exports';

// // Imports the Quiz component from Quiz.js for use in this file.
// // import Quiz from './Quiz';

// // Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
// Amplify.configure(awsExports);

function Home() {

    const [selectedPeriod, setSelectedPeriod] = useState("[25,25]"); // Default to Period 
    const [tickets, setTickets] = useState([]);
    const [ticketsgrouped, setGroupedTickets] = useState([]);
    const [cachedTable, setCachedTable] = useState([]);
    const [filteredticketsgrouped, setFilteredGroupedTickets] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState(() =>
        localStorage.getItem('currentPeriod') || null
    );
    const [isWeekday, setIsWeekday] = useState(null);
    const [mainSites, setmainSites] = useState([]);
    const [boostEventsSites, setBoostEventsSites] = useState([]);
    const [siteRegulations, setSiteRegulations] = useState([]);
    const [email, setEmail] = useState(null);
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [lastLatitude, setLastLatitude] = useState(() =>
        parseFloat(localStorage.getItem('wardenLastLatitude')) || null
    );
    const [lastLongitude, setLastLongitude] = useState(() =>
        parseFloat(localStorage.getItem('wardenLastLongitude')) || null
    );
    const [errorLocation, setErrorLocation] = useState(null);
    // const [userAreas, setUserAreas] = useState(() => {
    //     const storedAreas = localStorage.getItem('userAreas');
    //     return storedAreas ? JSON.parse(storedAreas) : [];
    // });
    const [selectedArea, setSelectedArea] = useState(() => {
        const storedselectedArea = localStorage.getItem('selectedArea');
        return storedselectedArea ? JSON.parse(storedselectedArea) : "All";
    });
    const [selectedAreas] = useState(() => {
        const storedSelectedArea = localStorage.getItem("selectedAreas");
        return storedSelectedArea ? JSON.parse(storedSelectedArea) : [];
    });
    
    const [loading, setLoading] = useState(true); // State to track loading status
    const [boostedSites, setBoostedSites] = useState([]); // New state for boosted sites
    const [events, setEvents] = useState([]);
    const [eventsFiltered, setEventsFiltered] = useState([]);
    const [groupedFilteredEvents, setGroupedFilteredEvents] = useState([]); // Store grouped and aggregated events
    const [sitesMapBox, setSitesMapBox] = useState(() => {
        const cachedRoutes = localStorage.getItem('mapBox');
        return cachedRoutes ? JSON.parse(cachedRoutes) : [];
    });
    const [boostRoutesSites, setBoostRoutesSites] = useState([]); // Store grouped and aggregated events
    const move_threshold = 500
    // const URLVAR = "http://127.0.0.1:8000"
    const URLVAR = "https://api.npcpatrolplan.com"


    useEffect(() => {
        // Check if we have cached data in local storage
        const cachedTickets = localStorage.getItem('maintable');

        // If cached data exists, use it immediately
        if (cachedTickets) {

            // Ensure it's an array
            if (Array.isArray(JSON.parse(cachedTickets))) {
                setCachedTable(JSON.parse(cachedTickets));
            } else {
                console.error('Cached data is not an array.');
            }
            setLoading(false); // Data is available, stop loading
        }
    }, [])

    // Fetch authentication token
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const session = await fetchAuthSession();
                const idToken = session.tokens?.idToken?.toString();

                if (idToken) {
                    const decodedToken = jwtDecode(idToken);
                    const email = decodedToken.email; // Assuming 'email' is the key for the user's email in the token
                    setEmail(email);
                }
            } catch (error) {
                console.error("Error fetching auth token:", error);
            }
        };

        fetchToken();
    }, []); // Run once on component mount

    useEffect(() => {
        const handleGetLastLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        if (lastLatitude !== null && lastLongitude !== null) {
                            // Calculate distance only if lastLocation is available
                            const distance = calculateDistance(
                                latitude,
                                longitude,
                                lastLatitude,
                                lastLongitude
                            );

                            if (distance > move_threshold) {
                                // Update state with new location
                                setLastLatitude(latitude)
                                setLastLongitude(longitude)
                                console.log("warden location changed");

                            }

                        } else {
                            setLastLatitude(latitude);
                            setLastLongitude(longitude);
                            console.log("Initial location stored.");
                        }

                    },
                    (error) => {
                        setErrorLocation("Error getting geolocation: " + error.message);
                    }
                );
            } else {
                setErrorLocation("Geolocation is not supported by this browser.");
            }
        };


        handleGetLastLocation();

        // Set interval to call the function every 10 seconds
        const intervalId = setInterval(handleGetLastLocation, 10000);

        // Clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, [lastLatitude, lastLongitude]); // This is kept for now in case you still want to track `lastLocation`



    // Helper function to calculate distance between two lat/lng coordinates
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Radius of the Earth in km
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km
        return distance * 1000; // Convert to meters
    };

    const calculatePeriodAndDay = useCallback(() => {
        const now = new Date();
        const hours = now.getHours();
        const day = now.getDay(); // 0 = Sunday, 6 = Saturday

        // Determine if it's a weekday or weekend
        const newIsWeekday = day >= 1 && day <= 5; // Monday to Friday

        // Determine the current period
        // let newPeriod = [hours, hours + 1]
        // newPeriod = JSON.stringify(newPeriod)
        let newPeriod = "";
        if (hours >= 0 && hours < 6) {
            newPeriod = "[0,6]"; // 12am - 6am
        } else if (hours >= 6 && hours < 9) {
            newPeriod = "[6,9]"; // 6am - 9am
        } else if (hours >= 9 && hours < 12) {
            newPeriod = "[9,12]"; // 9am - 12pm
        } else if (hours >= 12 && hours < 17) {
            newPeriod = "[12,17]"; // 12pm - 5pm
        } else if (hours >= 17 && hours < 20) {
            newPeriod = "[17,20]"; // 5pm - 8pm
        } else if (hours >= 20 && hours <= 23) {
            newPeriod = "[20,24]"; // 8pm - 12am
        }
        // Update state only if values have changed
        if (newIsWeekday !== isWeekday) {
            // console.log('newIsWeekday', newIsWeekday)
            setIsWeekday(newIsWeekday);
        }
        if (newPeriod !== currentPeriod) {
            // console.log('newPeriod', newPeriod)
            setCurrentPeriod(newPeriod);
        }
    }, [isWeekday, currentPeriod]); // Add dependencies

    useEffect(() => {
        // Calculate the period and day immediately on load
        calculatePeriodAndDay();

        // Set up interval to update every minute
        const interval = setInterval(calculatePeriodAndDay, 30000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [calculatePeriodAndDay]); // Dependency on useCallback function

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (selectedAreas.length > 0 && isWeekday !== null && currentPeriod.length !== 0) {
            const fetchTicketsData = async () => {
                console.log("Fetching tickets data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [ticketsResponse] = await Promise.all([
                        fetch(`${URLVAR}/api/tickets/grouped/?format=json`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                isWeekday: isWeekday,           // Pass the variable directly
                                currentPeriod: JSON.parse(currentPeriod),
                                areas: selectedAreas,
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!ticketsResponse.ok) {
                        throw new Error(
                            `Error fetching data: Tickets(${ticketsResponse.status})`
                        );
                    }

                    const ticketsResult = await ticketsResponse.json();

                    if (Array.isArray(ticketsResult)) {

                        setGroupedTickets(ticketsResult);

                    } else {
                        setGroupedTickets([]);
                    }
                } catch (error) {
                    console.error("Error fetching tickets data:", error);
                }
            };
            fetchTicketsData();
        }
    }, [selectedAreas, isWeekday, currentPeriod]); // Debounce fetch calls

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (selectedAreas.length > 0 && isWeekday !== null && currentPeriod.length !== 0 && lastLatitude !== null && lastLongitude !== null) {
            const storedLatitude = parseFloat(localStorage.getItem('wardenLastLatitude'));
            const storedLongitude = parseFloat(localStorage.getItem('wardenLastLongitude'));
            const storedCurrentPeriod = localStorage.getItem('currentPeriod');
            const storedSelectedAreas = JSON.parse(localStorage.getItem("lastSelectedAreas")) || [];
            const areasChanged = JSON.stringify(selectedAreas) !== JSON.stringify(storedSelectedAreas);
            if (areasChanged || storedCurrentPeriod !== currentPeriod || storedLatitude !== lastLatitude || storedLongitude !== lastLongitude) {
                const fetchMapBoxData = async () => {
                    console.log("Fetching mapbox routes data...");
                    try {
                        const session = await fetchAuthSession();
                        const accessToken = session.tokens?.accessToken?.toString();

                        if (!accessToken) {
                            console.error("No token available");
                            return;
                        }

                        // Fetch tickets and sites concurrently
                        const [mapboxResponse] = await Promise.all([
                            fetch(`${URLVAR}/api/tickets/mapbox/?format=json`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `${accessToken}`,
                                },
                                body: JSON.stringify({
                                    isWeekday: isWeekday,           // Pass the variable directly
                                    currentPeriod: JSON.parse(currentPeriod),
                                    areas: selectedAreas,
                                    wardenLat: lastLatitude,
                                    wardenLong: lastLongitude
                                }),
                            }),
                        ]);
                        // Check for HTTP errors before parsing JSON
                        if (!mapboxResponse.ok) {
                            throw new Error(
                                `Error fetching data: Mapbox(${mapboxResponse.status})`
                            );
                        }

                        const mapboxResult = await mapboxResponse.json();

                        if (Array.isArray(mapboxResult)) {
                            setSitesMapBox(mapboxResult);
                            localStorage.setItem('mapBox', JSON.stringify(mapboxResult));
                            console.log('Set mapbox to local storage.')
                            localStorage.setItem('wardenLastLatitude', lastLatitude);
                            localStorage.setItem('wardenLastLongitude', lastLongitude);
                            console.log('Location added to local storage.')
                            localStorage.setItem('currentPeriod', currentPeriod);
                            console.log('Current Period added to local storage.')
                            localStorage.setItem("lastSelectedAreas", JSON.stringify(selectedAreas));
                            console.log('lastSelectedAreas added to local storage.')
                        } else {
                            setSitesMapBox([]);
                        }
                    } catch (error) {
                        console.error("Error fetching mapbox data:", error);
                    }
                };
                fetchMapBoxData();
            } else {
                console.log('Fetch MapBox stopped. Location not changed.')
            }


        }
    }, [selectedAreas, isWeekday, currentPeriod, lastLatitude, lastLongitude]); // Debounce fetch calls

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (selectedAreas.length > 0) {
            const fetchEventsData = async () => {
                console.log("Fetching events data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [eventsResponse] = await Promise.all([
                        fetch(`${URLVAR}/api/ticketmasterevents/grouped/?format=json`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                areas: selectedAreas,           // Pass the variable directly
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!eventsResponse.ok) {
                        throw new Error(
                            `Error fetching data: eventsResponse(${eventsResponse.status})`
                        );
                    }

                    const eventsResult = await eventsResponse.json();

                    if (Array.isArray(eventsResult)) {
                        setEvents(eventsResult);
                    } else {
                        setEvents([]);
                    }
                } catch (error) {
                    console.error("Error fetching events data:", error);
                }
            };
            fetchEventsData();
        }
    }, [selectedAreas]); // Debounce fetch calls

    const filterCurrentEvents = useCallback(() => {
        const now = new Date();

        // Get today's date in YYYY-MM-DD format
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Set the time to midnight
        const startOfDay = today;
        const endOfDay = new Date(today).setHours(23, 59, 59, 999); // End of the day (11:59:59.999)

        // Filter events where the event start time and end time are within today and the current time
        const filtered = events.filter(event => {
            const eventStartTime = new Date(event.startdatetime);
            const eventEndTime = new Date(event.enddatetime);

            // Check if event start and end time are within today
            return (
                eventStartTime >= startOfDay &&
                eventEndTime <= endOfDay &&
                now >= eventStartTime &&  // Check if current time is between start and end time
                now <= eventEndTime
            );
        });

        setEventsFiltered(filtered);  // Set the filtered events to state
    }, [events]); // Only re-run when events change

    // Group filtered events by siteid and aggregate distance and count
    const groupAndAggregateEvents = useCallback(() => {
        if (eventsFiltered.length === 0) return;

        const grouped = eventsFiltered.reduce((acc, event) => {
            const site = event.site;

            // If the siteid group does not exist, create it
            if (!acc[site]) {
                acc[site] = { site, totalDistance: 0, eventCount: 0 };
            }

            // Aggregate the distance and count
            acc[site].totalDistance += event.distance;  // Sum the distances
            acc[site].eventCount += 1;  // Count the events

            return acc;
        }, {});

        // Convert the grouped object to an array
        const groupedArray = Object.values(grouped);
        setGroupedFilteredEvents(groupedArray);  // Set the grouped events with aggregated data

    }, [eventsFiltered]); // Re-run when eventsFiltered changes

    // Check every 5 minutes for active events
    useEffect(() => {
        filterCurrentEvents();  // Filter events on initial load

        const intervalId = setInterval(() => {
            filterCurrentEvents();  // Filter events every 5 minutes
        }, 5 * 60 * 1000); // 5 minutes

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [filterCurrentEvents]);

    // After filtering, group and aggregate the events
    useEffect(() => {
        groupAndAggregateEvents(); // Group and aggregate whenever eventsFiltered changes
    }, [eventsFiltered, groupAndAggregateEvents]);

    // Fetch regulations data when sitesdetailed changes
    useEffect(() => {
        if (selectedAreas.length > 0) {
            const fetchRegulationsData = async () => {
                console.log("Fetching regulations data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    const sitesRegulationsResponse = await fetch(
                        `${URLVAR}/api/sites/regulations/?format=json`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({ areas: selectedAreas }),
                        }
                    );

                    if (!sitesRegulationsResponse.ok) {
                        throw new Error(
                            `Error fetching data: Regulations(${sitesRegulationsResponse.status})`
                        );
                    }

                    const sitesRegulationsResults = await sitesRegulationsResponse.json();

                    if (Array.isArray(sitesRegulationsResults)) {
                        setSiteRegulations(sitesRegulationsResults);
                    } else {
                        setSiteRegulations([]);
                        setBoostedSites([]);
                    }
                } catch (error) {
                    console.error("Error fetching regulations data:", error);
                }
            };

            fetchRegulationsData();
        } else {
            setSiteRegulations([]); // Clear regulations if sitesdetailed is empty
        }
    }, [selectedAreas]); // Dependency includes checkAndUpdateBoost

    useEffect(() => {
        if (selectedArea === "All") {
            setFilteredGroupedTickets(ticketsgrouped)
        } else {
            setFilteredGroupedTickets(ticketsgrouped.filter((item) => selectedArea.includes(item.areaname)))
        }
        // setLoading(false)
    }, [ticketsgrouped, selectedArea]); // Run once on component mount

    const checkAndUpdateBoost = useCallback(() => {
        if (siteRegulations.length > 0) {
            const now = new Date();
            const currentDay = now.toLocaleString("en-US", { weekday: "short" }); // e.g., "Tue"
            const currentTime = now.toTimeString().slice(0, 5); // e.g., "13:31"

            // Filter regulations to determine which ones are boosted
            const boosted = siteRegulations
                .filter((regulation) => {
                    const startTime = regulation["Start Time"];
                    const endTime = regulation["End Time"];
                    const boostLevel = regulation["Boost Level"];
                    return (
                        regulation.Day === currentDay &&
                        currentTime >= startTime &&
                        currentTime <= endTime &&
                        boostLevel > 0
                    );
                })
                .map((regulation) => ({
                    ...regulation,
                    Boost: true, // Explicitly set Boost to true
                }));

            setBoostedSites(boosted); // Store only the boosted regulations with Boost: true
        } else {
            setBoostedSites([])
        }

    }, [siteRegulations]);

    useEffect(() => {
        if (filteredticketsgrouped.length > 0) {
            // If boostedSites is still loading or empty
            if (!boostedSites || boostedSites.length === 0) {
                // Set mainSites based on filteredticketsgrouped alone
                const defaultBoosts = Object.values(filteredticketsgrouped).map((ticketGroup) => ({
                    ...ticketGroup,
                    Boosted: false, // No regulation match yet
                    BoostLevel: 0,  // Default boost level
                    BoostTotalScore: ticketGroup.score // No boost applied yet
                }));
    
                // Set intermediate state
                setmainSites(defaultBoosts);
                return;
            }
    
            // Join filteredticketsgrouped with boostedSites
            const joinedBoosts = Object.values(filteredticketsgrouped).map((ticketGroup) => {
                const matchingRegulation = boostedSites.find(
                    (regulation) => regulation.Site === ticketGroup.site
                );
    
                // Constants for default values
                const isBoosted = !!matchingRegulation;
                const boostLevel = matchingRegulation?.["Boost Level"] ?? 0;
    
                return {
                    ...ticketGroup,
                    Boosted: isBoosted, // true if matching regulation exists
                    BoostLevel: boostLevel, // Boost Level or 0
                    BoostTotalScore: ticketGroup.score + boostLevel
                };
            });
    
            // Sort by BoostTotalScore in descending order
            joinedBoosts.sort((a, b) => b.BoostTotalScore - a.BoostTotalScore);
    
            // Set final mainSites with boosted scores
            setmainSites(joinedBoosts);
    
        } else {
            setmainSites([]); // If no filteredticketsgrouped, set empty array
        }
    }, [filteredticketsgrouped, boostedSites]); // Dependencies for useEffect
    

    useEffect(() => {
        if (mainSites.length > 0) {
            // If groupedFilteredEvents is still loading or empty
            if (!groupedFilteredEvents || groupedFilteredEvents.length === 0) {
                // Set boostEventsSites based on mainSites alone (default state)
                const defaultEvents = mainSites.map((sitesGroup) => ({
                    ...sitesGroup,
                    eventCount: 0, // No events yet
                    BoostEventTotalScore: sitesGroup.BoostTotalScore // No event-based score added
                }));
    
                // Set intermediate state
                setBoostEventsSites(defaultEvents);
                return;
            }
    
            // Create a Map for fast lookup of events by site
            const eventsMap = new Map(groupedFilteredEvents.map(event => [event.site, event]));
    
            // Join mainSites with groupedFilteredEvents
            const joinedEvents = mainSites.map((sitesGroup) => {
                const matchingEvents = eventsMap.get(sitesGroup.site); // O(1) lookup
    
                // Constants for default values
                const eventCount = matchingEvents?.eventCount ?? 0;
                const totalDistance = matchingEvents?.totalDistance ?? 0;
    
                // Calculate BoostEventTotalScore
                const BoostEventTotalScore = sitesGroup.BoostTotalScore +
                    (matchingEvents ? (1 / (1 + totalDistance)) * 2 : 0);
    
                return {
                    ...sitesGroup,
                    eventCount: eventCount,
                    BoostEventTotalScore: BoostEventTotalScore
                };
            });
    
            // Sort by BoostEventTotalScore in descending order
            joinedEvents.sort((a, b) => b.BoostEventTotalScore - a.BoostEventTotalScore);
            setBoostEventsSites(joinedEvents);
    
        } else {
            setBoostEventsSites([]); // If no mainSites, return an empty array
        }
    }, [mainSites, groupedFilteredEvents]); // Dependencies for useEffect
    

    useEffect(() => {
        if (boostEventsSites.length > 0) {
            // Handle when sitesMapBox is still loading or undefined
            if (!sitesMapBox || sitesMapBox.length === 0) {
                // Set boost routes based on boostEventsSites alone (no routes yet)
                const defaultRoutes = Object.values(boostEventsSites).map((sitesGroup) => ({
                    ...sitesGroup,
                    duration_str: "",
                    duration_seconds: 0,
                    distance_str: "",
                    distance: 0,
                    BoostEventRouteTotalScore: sitesGroup.BoostEventTotalScore // No route scores added
                }));
    
                // Set this intermediate state
                setBoostRoutesSites(defaultRoutes);
                return;
            }
    
            // When sitesMapBox is available, join boostEventsSites and sitesMapBox
            const joinedRoutes = Object.values(boostEventsSites).map((sitesGroup) => {
                const matchingRoutes = sitesMapBox.find(
                    (route) => route.site === sitesGroup.site
                );
    
                // Constants for properties with default values
                const durationStr = matchingRoutes?.duration_str ?? "";
                const durationSeconds = matchingRoutes?.duration_seconds ?? 0;
                const distanceStr = matchingRoutes?.distance_str ?? "";
                const distance = matchingRoutes?.distance ?? 0;
    
                return {
                    ...sitesGroup,
                    duration_str: durationStr,
                    duration_seconds: durationSeconds,
                    distance_str: distanceStr,
                    distance: distance,
                    BoostEventRouteTotalScore:
                        sitesGroup.BoostEventTotalScore +
                        (matchingRoutes ? (1 / (1 + (durationSeconds / 3000))) * 5 : 0)
                };
            });
    
            // Sort by BoostEventRouteTotalScore
            joinedRoutes.sort((a, b) => b.BoostEventRouteTotalScore - a.BoostEventRouteTotalScore);
    
            // Set final boost routes with route information
            setBoostRoutesSites(joinedRoutes);
    
        } else {
            setBoostRoutesSites([]);
        }
    }, [boostEventsSites, sitesMapBox]); // Runs whenever boostEventsSites or sitesMapBox changes
    

    useEffect(() => {
        checkAndUpdateBoost(); // Run once immediately
        const interval = setInterval(checkAndUpdateBoost, 30000); // Every 60 seconds
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [checkAndUpdateBoost]);

    useEffect(() => {
        if (boostRoutesSites.length > 0) {
            localStorage.setItem('maintable', JSON.stringify(boostRoutesSites)); // Cache the new data
            setLoading(false)
        } else if (boostRoutesSites.length === 0) {
            // Wait 1 second before setting loading to false if no events are found
            const timeout = setTimeout(() => {
                setLoading(false);
            }, 5000);  // 1 second timeout to show 'loading' before setting it to false

            return () => clearTimeout(timeout);  // Cleanup timeout if the component unmounts
        }
    }, [boostRoutesSites])

    const periods = [
        { value: "[25,25]", label: "Current" },
        { value: "[0,6]", label: "12am - 6am" },
        { value: "[6,9]", label: "6am - 9am" },
        { value: "[9,12]", label: "9am - 12pm" },
        { value: "[12,17]", label: "12pm - 5pm" },
        { value: "[17,20]", label: "5pm - 8pm" },
        { value: "[20,24]", label: "8pm - 12am" },
        { value: "[0,24]", label: "All day" }
    ];

    const handlePeriodChange = (event) => {
        setLoading(true)
        setSelectedPeriod(event.target.value);
    };

    const handleAreaChange = (event) => {
        setCachedTable([])
        setLoading(true)
        setSelectedArea(event.target.value);
        localStorage.setItem('selectedArea', JSON.stringify(event.target.value));
        // console.log("Selected Area:", event.target.value);
    };

    return (
        <header className='App-header'>
            <div className='main-section'>
                <h1 className='header'>Ticketing Patrol Zones</h1>
                {/* <p>Hi {email}!</p> */}
                {/* {error && <p>{error}</p>} */}
                {/* {location.latitude && location.longitude ? (
                    <div className='current-location-section'>
                        <p className='current-location-p'>Your current location:</p>
                        <p className='current-location-p'>Latitude: {location.latitude}</p>
                        <p className='current-location-p'>Longitude: {location.longitude}</p>
                    </div>
                ) : (
                    <p>{errorLocation ? `Error: ${errorLocation}` : 'Location not available yet.'}</p>
                )} */}
                <div className='area-dropdown-section'>
                    <label htmlFor="area-dropdown">Select Area: </label>
                    <select
                        id="area-dropdown"
                        value={selectedArea}
                        onChange={handleAreaChange}
                    >
                        <option value="All">
                            All
                        </option>
                        {selectedAreas.length > 0 ? (
                            selectedAreas.map((area, index) => (
                                <option key={index} value={area}>
                                    {area}
                                </option>
                            ))
                        ) : (
                            <option disabled>No areas available</option>
                        )}
                    </select>
                </div>
                {/* <div>
                                <label htmlFor="period-dropdown">Select Period: </label>
                                <select
                                    id="period-dropdown"
                                    value={selectedPeriod}
                                    onChange={handlePeriodChange}
                                >
                                    {periods.map((period) => (
                                        <option key={period.label} value={period.value}>
                                            {period.label}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                {/* Tickets Table */}
                {loading ? (
                    // Show loader animation while loading
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <div style={{
                            width: '50px',
                            height: '50px',
                            border: '5px solid #f3f3f3',
                            borderTop: '5px solid #3498db',
                            borderRadius: '50%',
                            animation: 'spin 1s linear infinite'
                        }}></div>
                        <style>
                            {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      `}
                        </style>
                    </div>
                ) : (cachedTable.length > 0 || boostRoutesSites.length > 0) ? (
                    // Show table if data is available
                    <table style={{ borderCollapse: 'collapse', marginTop: '20px' }} className='main-table'>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Site Name</th>
                                {/* <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Reg</th> */}
                                <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Miles</th>
                                <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Route</th>
                                <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(boostRoutesSites.length > 0 ? boostRoutesSites : cachedTable).map((ticket, index) => {
                                const mainScore = ticket.BoostEventRouteTotalScore.toFixed(2) // Format BoostTotalScore to 2 decimal places
                                const distance = ticket.distance.toFixed(2) // Format BoostTotalScore to 2 decimal places
                                const rowColor = ticket.Boosted
                                    ? 'rgba(21, 153, 76, 0.7)' // Green background
                                    : `rgba(15, 94, 113, ${1 - (0.7 * index) / (boostRoutesSites.length > 0 ? boostRoutesSites : cachedTable).length})`; // Gradient blue
                                return (

                                    <tr key={ticket.sitename} style={{ backgroundColor: rowColor }}>
                                        <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>
                                            <Link
                                                to={{
                                                    pathname: `/auth/site/${ticket.site}/${encodeURIComponent(ticket.sitename)}`, // Dynamic URL with sitename
                                                }}
                                                state={{
                                                    addressline1: ticket.addressline1,
                                                    addressline2: ticket.addressline2,
                                                    addressline3: ticket.addressline3,
                                                    addressline4: ticket.addressline4,
                                                    areaname: ticket.areaname,
                                                    city: ticket.city,
                                                    countrycode: ticket.countrycode,
                                                    county: ticket.county,
                                                    postcode: ticket.postcode,
                                                    isboost: ticket.Boosted,
                                                    mainscore: ticket.BoostEventRouteTotalScore.toFixed(2),
                                                    eventcount: ticket.eventCount,
                                                    ticketcount: ticket.ticketcount,
                                                    distance: ticket.distance_str,
                                                    duration: ticket.duration_str,
                                                    baseticketscore: ticket.score.toFixed(2),
                                                    boostscore: ticket.BoostLevel,
                                                    eventscore: (ticket.BoostEventTotalScore - ticket.BoostTotalScore).toFixed(2),
                                                    routescore: (ticket.BoostEventRouteTotalScore - ticket.BoostEventTotalScore).toFixed(2),
                                                    currentperiod: currentPeriod,
                                                    isweekday: isWeekday,
                                                }} // Pass state here
                                                style={{
                                                    color: 'white',           // Make the text white
                                                    textDecoration: 'none',   // Remove the underline
                                                }}
                                            >
                                                {ticket.sitename}
                                            </Link>
                                        </td>
                                        {/* <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{ticket.regulations}</td> */}
                                        {/* <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{ticket.Boosted ? ticket.BoostLevel : ''}</td> */}
                                        <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{distance}</td>
                                        <td id='durationCol' style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{ticket.duration_str}</td>
                                        <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{mainScore}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    // Fallback for no data
                    <p>No tickets available.</p>
                )}
            </div>
            {/* <button
                            onClick={signOut}
                            style={{
                                margin: '20px',
                                fontSize: '0.8rem',
                                padding: '5px 10px',
                                marginTop: '30px',
                                marginBottom: '100px',
                                cursor: 'pointer',     // Makes the cursor a pointer on hover
                                backgroundColor: '#007BFF', // Button background color
                                color: '#fff',         // Text color
                                border: 'none',        // Removes border
                                borderRadius: '5px',   // Rounds the button's corners slightly
                                boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Adds a subtle shadow
                            }}
                        >
                            Sign Out
                        </button> */}

        </header>
    );
}

export default Home;