import { useLocation, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import '../App.css';
import '../styles/sitePage.css';
import { fetchAuthSession } from 'aws-amplify/auth';
import { jwtDecode } from 'jwt-decode';

function LocationChoosePage() {
    const [userAreas, setUserAreas] = useState(() => {
        const storedAreas = localStorage.getItem("userAreas");
        return storedAreas ? JSON.parse(storedAreas) : [];
    });

    const [selectedAreas, setSelectedAreas] = useState(() => {
        const storedSelected = localStorage.getItem("selectedAreas");
        return storedSelected ? JSON.parse(storedSelected) : [];
    });

    const [email, setEmail] = useState(null);
    // const URLVAR = "http://127.0.0.1:8000"
    const URLVAR = "https://api.npcpatrolplan.com";

    // Fetch authentication token
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const session = await fetchAuthSession();
                const idToken = session.tokens?.idToken?.toString();

                if (idToken) {
                    const decodedToken = jwtDecode(idToken);
                    const email = decodedToken.email; // Assuming 'email' is the key for the user's email in the token
                    setEmail(email);
                }
            } catch (error) {
                console.error("Error fetching auth token:", error);
            }
        };

        fetchToken();
    }, []); // Run once on component mount

    useEffect(() => {
        if (email) {
            const fetchUserAreas = async () => {
                console.log("Fetching fetchUserAreas data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }
                    const validEmails = ["thisisgurpal@gmail.com"];
                    const allCheck = validEmails.includes(email);

                    const [userAreasResponse] = await Promise.all([
                        fetch(`${URLVAR}/api/people/areas/?format=json`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                email: allCheck ? "All" : email,
                            }),
                        }),
                    ]);

                    if (!userAreasResponse.ok) {
                        throw new Error(
                            `Error fetching data: UserAreas(${userAreasResponse.status})`
                        );
                    }

                    const userAreasResult = await userAreasResponse.json();

                    if (userAreasResult && Array.isArray(userAreasResult.areanames)) {
                        const storedAreas = localStorage.getItem("userAreas");
                        const parsedStoredAreas = storedAreas ? JSON.parse(storedAreas) : [];

                        // Compare fetched areas with stored areas
                        const areasChanged =
                            JSON.stringify(userAreasResult.areanames) !== JSON.stringify(parsedStoredAreas);

                        if (areasChanged) {
                            setSelectedAreas([])
                            localStorage.removeItem('selectedAreas');
                            console.log("Removed selectedAreas from local storage.");
                            localStorage.removeItem('selectedArea');
                            console.log("Removed selectedArea from local storage.");
                            localStorage.removeItem('maintable');
                            console.log("Removed maintable from local storage.");
                            setUserAreas(userAreasResult.areanames);
                            localStorage.setItem(
                                "userAreas",
                                JSON.stringify(userAreasResult.areanames)
                            );
                            console.log("Set userAreas to local storage.");
                        }

                        } else {
                            setUserAreas([]);
                            console.log(
                                "Invalid userAreasResult or missing areanames. Set userAreas to an empty array."
                            );
                        }
                    } catch (error) {
                        console.error("Error fetching userAreas data:", error);
                    }
                };

                fetchUserAreas();
            }
        }, [email, userAreas]);

    // Sync selectedAreas to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("selectedAreas", JSON.stringify(selectedAreas));

    }, [selectedAreas]);

    // Handle checkbox toggle
    const handleCheckboxChange = (area) => {

        const cachedTickets = localStorage.getItem('maintable');

        if (cachedTickets){
            console.log('removed maintable from local')
            localStorage.removeItem('maintable');
        }

        const cachedArea = localStorage.getItem('selectedArea');

        if (cachedArea){
            localStorage.removeItem('selectedArea');
            console.log("Removed selectedArea from local storage.");
        }
        

        setSelectedAreas((prevSelected) =>
            prevSelected.includes(area)
                ? prevSelected.filter((a) => a !== area) // Remove if already selected
                : [...prevSelected, area] // Add if not selected
        );
    };

    return (
        <div className="App-header">
            <h5>Hi {email}</h5>
            <h3>Select User Areas</h3>
            {userAreas.length > 0 ? (
                <div>
                    {userAreas.map((area) => (
                        <label key={area} style={{ display: "block", marginBottom: "8px", fontSize: "0.875rem" }}>
                            <input
                                type="checkbox"
                                value={area}
                                checked={selectedAreas.includes(area)}
                                onChange={() => handleCheckboxChange(area)}
                            />
                            {area}
                        </label>
                    ))}
                </div>
            ) : (
                <p>Loading areas...</p>
            )}
            <div style={{ marginTop: "20px" }}>
                <Link to="/auth/home" className="sign-in-button">
                    Continue
                </Link>
            </div>
        </div>
    );
}

export default LocationChoosePage;
