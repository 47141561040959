import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/PreAuthPage.css'; // Custom styles

function PreAuthPage() {
  return (
    <div className="pre-auth-container">
      <div className="pre-auth-content">
        <h1 className="pre-auth-title">Welcome to PatrolPlan</h1>
        <p className="pre-auth-subtitle">Sign in to access your account.</p>
        <Link to="/auth" className="sign-in-button">
          Sign In
        </Link>
      </div>
    </div>
  );
}

export default PreAuthPage;
