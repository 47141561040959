// Footer.js
import React from 'react';
import '../styles/Footer.css'; // Optional: Include this if you want to add custom CSS

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2025 PatrolPlan. All rights reserved.</p>
      {/* <div>
        <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a>
      </div> */}
    </footer>
  );
}

export default Footer;