import { useLocation, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import '../App.css';
import '../styles/sitePage.css'
import { fetchAuthSession } from 'aws-amplify/auth'
import TicketCountChart from './TicketChart';

function SitePage() {
    const { siteid, sitename } = useParams(); // Get siteid from the URL
    const location = useLocation();  // Access the location object
    const queryParams = new URLSearchParams(location.search);
    const [events, setEvents] = useState([]);
    const [eventsFiltered, setEventsFiltered] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading status
    const [ticketsCurrentGrouped, setTicketsCurrentGrouped] = useState([]); // State to track loading status
    const [ticketsHourGrouped, setTicketsHourGrouped] = useState([]); // State to track loading status
    const [hasInitialized, setHasInitialized] = useState(false);
    const { state } = location;
    // const URLVAR = "http://127.0.0.1:8000"
    const URLVAR = "https://api.npcpatrolplan.com"

    // Extract state values
    const {
        addressline1,
        addressline2,
        addressline3,
        addressline4,
        areaname,
        city,
        countrycode,
        county,
        postcode,
        isboost,
        mainscore,
        eventcount,
        ticketcount,
        distance,
        duration,
        baseticketscore,
        boostscore,
        eventscore,
        routescore,
        currentperiod,
        isweekday
    } = state || {}; // Default to empty object in case state is undefined

    useEffect(() => {
        if (siteid && !hasInitialized) {
            setHasInitialized(true); // Prevent re-execution
        }
    }, [siteid, sitename, hasInitialized]); // Include hasInitialized as a dependency

    // Function to convert a string like "[6,9]" into "6am - 9am"
    const convertPeriodString = (input) => {
        // Remove square brackets and split the string into an array of numbers
        const range = input
            .replace("[", "")
            .replace("]", "")
            .split(",")
            .map(Number); // Convert strings to numbers

        // Helper function to format an hour into "am/pm"
        const formatHour = (hour) => {
            const period = hour >= 12 ? "pm" : "am";
            const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 24-hour to 12-hour format
            return `${formattedHour}${period}`;
        };

        // Convert the start and end hours
        if (range.length === 2) {
            const [start, end] = range;
            return `${formatHour(start)} - ${formatHour(end)}`;
        }

        // If the input format is invalid
        return "Invalid time range";
    };

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (siteid && hasInitialized) {
            const fetchTickets6MonthCurrentData = async () => {
                console.log("Fetching 6 month tickets grouped data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [ticketsResponse] = await Promise.all([
                        fetch(`${URLVAR}/api/tickets/6month-grouped-current/?format=json`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                siteids: [siteid],           // Pass the variable directly
                                currentPeriod: JSON.parse(currentperiod),
                                isWeekday: isweekday
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!ticketsResponse.ok) {
                        throw new Error(
                            `Error fetching data: 6 month Tickets(${ticketsResponse.status})`
                        );
                    }

                    const ticketsResult = await ticketsResponse.json();

                    if (Array.isArray(ticketsResult)) {
                        setTicketsCurrentGrouped(ticketsResult);
                    } else {
                        setTicketsCurrentGrouped([]);
                    }
                } catch (error) {
                    console.error("Error fetching 6 month tickets data:", error);
                }
            };
            fetchTickets6MonthCurrentData();
        }
    }, [siteid, hasInitialized, currentperiod, isweekday]); // Debounce fetch calls

    useEffect(() => {
        if (siteid && hasInitialized) {
            const fetchTicketsHourData = async () => {
                console.log("Fetching Hour tickets grouped data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [ticketsResponse] = await Promise.all([
                        fetch(`${URLVAR}/api/tickets/hour-grouped/?format=json`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                siteids: [siteid],           // Pass the variable directly
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!ticketsResponse.ok) {
                        throw new Error(
                            `Error fetching data: Hour Tickets(${ticketsResponse.status})`
                        );
                    }

                    const ticketsResult = await ticketsResponse.json();

                    if (Array.isArray(ticketsResult)) {
                        setTicketsHourGrouped(ticketsResult);
                    } else {
                        setTicketsHourGrouped([]);
                    }
                } catch (error) {
                    console.error("Error fetching Hour tickets data:", error);
                }
            };
            fetchTicketsHourData();
        }
    }, [siteid, hasInitialized]); // Debounce fetch calls

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (siteid && eventcount > 0 && hasInitialized) {
            const fetchEventsData = async () => {
                console.log("Fetching events data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [eventsResponse] = await Promise.all([
                        fetch(`${URLVAR}/api/ticketmasterevents/?format=json`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                siteids: [siteid],           // Pass the variable directly
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!eventsResponse.ok) {
                        throw new Error(
                            `Error fetching data: eventsResponse(${eventsResponse.status})`
                        );
                    }

                    const eventsResult = await eventsResponse.json();

                    if (Array.isArray(eventsResult)) {
                        setEvents(eventsResult);
                    } else {
                        setEvents([]);
                    }
                } catch (error) {
                    console.error("Error fetching events data:", error);
                }
            };
            fetchEventsData();
        }

    }, [siteid, eventcount, hasInitialized]); // Debounce fetch calls

    const filterCurrentEvents = useCallback(() => {
        const now = new Date();

        // Get today's date in YYYY-MM-DD format
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Set the time to midnight
        const startOfDay = today;
        const endOfDay = new Date(today).setHours(23, 59, 59, 999); // End of the day (11:59:59.999)

        // Filter events where the event start time and end time are within today and the current time
        const filtered = events.filter(event => {
            const eventStartTime = new Date(event.startdatetime);
            const eventEndTime = new Date(event.enddatetime);

            // Check if event start and end time are within today
            return (
                eventStartTime >= startOfDay &&
                eventEndTime <= endOfDay &&
                now >= eventStartTime &&  // Check if current time is between start and end time
                now <= eventEndTime
            );
        });

        setEventsFiltered(filtered);  // Set the filtered events to state
    }, [events]); // Only re-run when events change

    // Check every 5 minutes for active events
    useEffect(() => {
        filterCurrentEvents();  // Filter events on initial load

    }, [filterCurrentEvents]);

    useEffect(() => {
        if (eventsFiltered.length > 0) {
            setLoading(false)
        } else if (eventsFiltered.length === 0) {
            // Wait 1 second before setting loading to false if no events are found
            const timeout = setTimeout(() => {
                setLoading(false);
            }, 3000);  // 1 second timeout to show 'loading' before setting it to false

            return () => clearTimeout(timeout);  // Cleanup timeout if the component unmounts
        }
    }, [eventsFiltered])

    return (
        <div className="App-header">
            <div style={{ marginTop: "20px" }}>
                            <Link to="/auth/home" className="sign-in-button">
                                Back
                            </Link>
                        </div>
            {/* Site Details Section */}
            <div className="box-container">
                <h2>{decodeURIComponent(sitename)}</h2>
                {isboost === 'true' && <h4 style={{ color: 'green', fontWeight: 'bold' }}>
                    Boosted (+{boostscore})
                </h4>}

                <div className='box-card'>
                    <h4 className="box-title">Score Breakdown</h4>
                    <p className='box-text' style={{ color: 'grey', fontWeight: 'normal' }}>
                        + Ticket Score: {baseticketscore}<br />
                        + Boost Score: {boostscore}<br />
                        + Event Score: {eventscore}<br />
                        + Route Score: {routescore}<br />
                    </p>
                    <p className='box-text' style={{ fontWeight: 'normal' }}>
                        = Main Score: {mainscore}<br />
                    </p>
                </div>
                <div className='box-card'>
                    <h4 className="box-title">Route</h4>
                    <p className='box-text'>
                        Distance: {distance}<br />
                        Duration: {duration}<br />
                    </p>
                </div>
                <div className='box-card'>
                    <h4 className="box-title">Site Address</h4>
                    <p className='box-text'><strong>Address Line 1:</strong> {addressline1}<br />
                        <strong>Address Line 2:</strong> {addressline2}<br />
                        <strong>Address Line 3:</strong> {addressline3 || 'N/A'}<br />
                        <strong>Address Line 4:</strong> {addressline4 || 'N/A'}<br />
                        <strong>Area Name:</strong> {areaname || 'N/A'}<br />
                        <strong>City:</strong> {city}<br />
                        <strong>Country:</strong> {countrycode}<br />
                        <strong>County:</strong> {county || 'N/A'}<br />
                        <strong>Postcode:</strong> {postcode}</p>
                </div>
                <div className='box-card'>
                    <h4 className="box-title">Site Counts ({convertPeriodString(currentperiod)})</h4>
                    <p className='box-text'>
                        Ticket Count: {ticketcount}<br />
                        <TicketCountChart data={ticketsCurrentGrouped} name='monthChart' />
                    </p>
                </div>
                <div className='box-card'>
                    <h4 className="box-title">Site Counts (Last 6 months)</h4>
                    <TicketCountChart data={ticketsHourGrouped} name='hourChart' />
                </div>
                {/* Events Table Section */}
                <div className='box-card'>
                    <h4 className="box-title">Events Counts ({convertPeriodString(currentperiod)}):</h4>
                    <p className='box-text'>
                        Event Count: {eventcount}<br />
                    </p>
                    {loading ? (
                        // Show loader animation while loading
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <div style={{
                                width: '50px',
                                height: '50px',
                                border: '5px solid #f3f3f3',
                                borderTop: '5px solid #3498db',
                                borderRadius: '50%',
                                animation: 'spin 1s linear infinite'
                            }}></div>
                            <style>
                                {`
                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                        `}
                            </style>
                        </div>
                    ) : eventsFiltered.length > 0 ? (
                        <>
                            {eventsFiltered.map((event, index) => {
                                return (
                                    <div key={index} className="box-card event-card">
                                        <p className="box-text">
                                            <strong>Event Name:</strong> {event.name}<br />
                                            <strong>Start:</strong> {event.startdatetime}<br />
                                            <strong>End:</strong> {event.enddatetime}<br />
                                            <strong>Distance From Site:</strong> {event.distance} miles<br />
                                            <strong>Address Line 1:</strong> {event.venue_address_line_1}<br />
                                            <strong>Address Line 2:</strong> {event.venue_address_line_2}<br />
                                            <strong>City:</strong> {event.venue_city}<br />
                                            <strong>Postcode:</strong> {event.venue_postcode}<br />
                                            <strong>Venue Name:</strong> {event.venue_name}<br />
                                            <strong>URL:</strong> <a href={event.url} target="_blank" rel="noopener noreferrer" className="box-url">{event.url}</a><br />
                                        </p>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        // Fallback for no events
                        <p></p>
                    )}
                </div>
            </div>



        </div>
    );

}

export default SitePage;