// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import '../styles/Navbar.css'; // Optional: Include this if you want to add custom CSS

const Navbar = ({ signOut }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the dropdown menu

  // Toggle the menu
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Pre-signout action
  const handlePreSignOut = () => {
    // You can perform any action here before signing out (e.g., show a confirmation dialog)
    // Clear relevant data from localStorage before sign out
    localStorage.removeItem('wardenLastLatitude');
    localStorage.removeItem('wardenLastLongitude');
    localStorage.removeItem('maintable');
    localStorage.removeItem('mapBox');
    localStorage.removeItem('userAreas');
    localStorage.removeItem('currentPeriod');
    localStorage.removeItem('selectedArea');
    localStorage.removeItem('lastSelectedAreas');
    localStorage.removeItem('selectedAreas');
    

    // Perform any other action before signing out
    console.log("Cleared data from localStorage");
    
    signOut();
};

  return (
    <nav className="navbar">
      {/* Logo */}
      <div className="logo">
        <Link to={{pathname: `/auth`}}>
          PatrolPlan
        </Link>
      </div>

      {/* Dropdown menu button (for small screens) */}
      <div className="menu-icon" onClick={toggleMenu}>
        <span className="menu-bar"></span>
        <span className="menu-bar"></span>
        <span className="menu-bar"></span>
      </div>

      {/* Dropdown menu for links and sign-out */}
      <div >
        <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
          <li className="navbar-item"> <Link to={{pathname: `/auth`}}>Home</Link></li>
          {/* <li className="navbar-item"><a href="/services">Services</a></li>
          <li className="navbar-item"><a href="/contact">Contact</a></li> */}
          <li className="navbar-item">
            <button onClick={handlePreSignOut} className="sign-out-btn">Sign Out</button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
