import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../components/Home";
import SitePage from "../components/SitePage";
import PreAuthPage from "../components/PreAuthPage";
import LocationChoosePage from "../components/locationChoosePage";

export const router = createBrowserRouter([
  { path: "/", element: <PreAuthPage /> }, // Pre-auth landing page
  {
    path: "/auth",
    element: <App />, // Main wrapper component
    children: [
      { path: "", element: <LocationChoosePage /> }, // Home route
      { path: "home", element: <Home /> }, // Home route
      { path: "site/:siteid/:sitename", element: <SitePage /> }, // Home route
    ],
  },
]);