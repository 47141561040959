import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels); // Register the plugin

const TicketCountChart = ({ data, name }) => {
  // Prepare data for the chart
  const chartData = {
    labels: data.map(item => name === 'hourChart' ? item.time_period : name === 'monthChart' ? item.year_month : ''), // X-axis labels (Month Year)
    datasets: [
      {
        label: 'Ticket Counts', // Label for the dataset
        data: data.map(item => item.ticket_count), // Y-axis data (ticket counts)
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Bar color
        borderColor: 'rgba(54, 162, 235, 1)', // Bar border color
        borderWidth: 1,
      }
    ]
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: name === 'hourChart' ? 'Ticket Counts for the Hour Periods' : name === 'monthChart' ? 'Ticket Counts for the Last 6 Months' : '',
      },
      datalabels: {
        color: '#183039', // Set the color of the data labels
        align: 'top', // Position the label at the top of the bar
        font: {
          weight: 'bold', // Make the labels bold
        },
        formatter: (value) => value, // Use the ticket count value as the label
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: true, // Skip labels if they are too long
        }
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', margin: 'auto' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TicketCountChart;
